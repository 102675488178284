import { useContext, useEffect } from "react";
import { AppRoutes } from "./Routes/AppRoutes";
import { getStoragedItem } from "./utils/helpers/storageHelpers";
import { AuthContext } from "./contexts/AuthContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export function App() {
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const data = getStoragedItem("LOGIN_DATA");
    if (data !== null) {
      ctx?.setIsAuth(true);
      ctx?.setFullName(data.fullName);
      ctx?.setRole(data.email)
    }
  }, [ctx]);

  return <>
    <ToastContainer />
    <AppRoutes isAuth={Boolean(ctx?.isAuth)} role={ctx?.role as string} />
  </>;
}

export default App;
