import {
    Navigate,
    RouterProvider,
    createBrowserRouter, createHashRouter,
} from "react-router-dom";
import { MainLayout } from "../layout/MainLayout";
import { PrivateRoute } from "./PrivateRoute";
import { Suspense, lazy, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const Login = lazy(() => import("../pages/Login"));
const Orders = lazy(() => import("../pages/Orders"));
const Users = lazy(() => import("../pages/Users"));
const Settings = lazy(() => import("../pages/Settings"));


export const AppRoutes = ({ isAuth,role }: { isAuth: boolean,role:string }) => {
  const ctx = useContext(AuthContext);
  const router = createHashRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/login",
          element: (
            <PrivateRoute
              component={
                <Suspense fallback="loading">
                  <Login />
                </Suspense>
              }
              fallbackPath="/orders"
              isAuth={!isAuth}
            />
          ),
        },
        {
          path: "/",
          element: <Navigate to={ctx?.isAuth ? "/orders" : "/login"} />,
        },
        {
          path: "/orders",
          element: (
            <PrivateRoute
              component={
                <Suspense fallback="loading">
                  <Orders />
                </Suspense>
              }
              fallbackPath="/"
              isAuth={isAuth}
            />
          ),
        },
        {
          path: "/users",
          element: (
            <PrivateRoute
              component={
                <Suspense fallback="loading">
                  <Users />
                </Suspense>
              }
              fallbackPath="/"
              isAuth={isAuth}
            />
          ),
        },
        {
          path: "/settings",
          element: (
            <PrivateRoute
              component={
                <Suspense fallback="loading">
                  <Settings />
                </Suspense>
              }
              fallbackPath="/"
              isAuth={isAuth}
            />
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
