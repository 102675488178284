import { createPortal } from "react-dom";
import { NavLink } from "react-router-dom";
import {IconButton} from "./IconButton";
import {ArrowSvg,OrderIcon,UserIcon,SettingsIcon} from "../../assets/icon-collection";

type Props = {
  open: boolean;
  onClose: () => void;
  role?: string
};

export const SideBar = ({ open, onClose,role }: Props) => {

  const isAdmin = role === 'admin'
  return (
    open &&
    createPortal(
      <>
        <div
          onClick={onClose}
          className="bg-black absolute top-0 w-full h-full opacity-40"
        ></div>
        <div className="sm:w-96 w-64 min-h-[94.5vh] bg-white absolute top-10 left-0">
          <ul className="flex flex-col items-start">
            <li className="border-b border-[#d2d2d2] mx-auto w-52 pb-5 mb-5 mt-10  text-orange font-bold cursor-pointer">
              <NavLink
                to="/orders"
                onClick={onClose}
                className={"flex items-center gap-2 justify-between"}
              >
                <div className="flex items-center gap-2">
                  <OrderIcon />
                  Bestellungen
                </div>
                <ArrowSvg />

              </NavLink>
            </li>
            {isAdmin && <li className="border-b border-[#d2d2d2] mx-auto w-52 pb-5 mb-5  text-orange font-bold cursor-pointer">
              <NavLink
                  to="/users"
                  onClick={onClose}
                  className={"flex items-center gap-2 justify-between"}
              >
                <div className="flex items-center gap-2">
                  <UserIcon/>
                  Benutzer
                </div>
                <ArrowSvg/>
              </NavLink>
            </li>}
            <li className="mx-auto w-52  text-orange font-bold cursor-pointer">
              <NavLink
                  to="/settings"
                  onClick={onClose}
                  className={"flex items-center gap-2 justify-between"}
              >
                <div className="flex items-center gap-2">
                  <SettingsIcon/>
                  Einstellungen
                </div>

                <ArrowSvg/>
              </NavLink>
            </li>
          </ul>
        </div>
      </>,
      document.body
    )
  );
};
