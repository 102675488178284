import { ReactElement, } from "react";
import { Navigate } from "react-router-dom";

type Props = {
  component: ReactElement;
  fallbackPath: string;
  isAuth: boolean;
  role?: string
};

export const PrivateRoute = ({ component: Component, fallbackPath, isAuth }: Props) => {

  if (!isAuth) {
    return <Navigate to={fallbackPath} />;
  }
  return Component;
};
