export const BASE_URL = "https://192.1.1.2:3333";

export const SELECT_OPTIONS = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "user",
    label: "User",
  },
];

export const TOAST_MESSAGES = {
  on_update: "Successfully updated!",
  change_saved: "Changes saved successfully!",
  on_create: "Successfully created!",
  on_delete: "Successfully deleted!",
  on_update_error: "Update failed. Please try again.",
  on_create_error: "Creation failed. Please try again.",
  on_delete_error: "Deletion failed. Please try again.",
};
